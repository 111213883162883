/* QuestionNode.css */

.question-node {
    background-color: #fff;
    border: 1px solid #bbb;
    border-radius: 5px;
    padding: 10px;
    min-width: 250px;
  }
  
  .node-header {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .question-input {
    width: 100%;
    height: 60px;
    margin-bottom: 10px;
    resize: none;
  }
  
  .answers-section {
    margin-top: 10px;
  }
  
  .answers-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .add-answer-button {
    background-color: #4caf50;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
  }
  
  .answer-item {
    display: flex;
    align-items: center;
    margin-top: 5px;
    position: relative; /* Necessary for absolute positioning of the handle */
  }
  
  .answer-input {
    flex: 1;
    margin-right: 5px;
    padding: 5px;
  }
  
  .remove-answer-button {
    background-color: #e74c3c;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
  }
  
  .answer-handle {
    position: absolute !important; /* Ensure the handle is absolutely positioned */
    right: -8px; /* Adjust as needed to position the handle */
    top: 50%;
    transform: translateY(-50%);
    background-color: #555;
    border: none;
    width: 10px;
    height: 10px;
  }
  