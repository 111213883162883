/* App.css */

.flow-builder {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .header {
    padding: 10px;
    background-color: #2196f3;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .add-node-button {
    background-color: #fff;
    color: #2196f3;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .flow-container {
    flex: 1;
  }
  